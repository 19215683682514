import { Currency, CurrencyAmount, JSBI, Token, Trade, Percent, ETHER, TokenAmount, Pair } from '@uniswap/sdk'
import { WETH } from 'constants/token'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import ReactGA from 'react-ga'
// import { Input } from 'react-input-component';
import { Text } from 'rebass'
// import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonLight, ButtonPrimary, ButtonConfirmed } from '../../components/Button'
import Card, { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
// import { LongTermWindowInputPanel } from '../../components/LongTermWindowInput'
import LongTermBlockInputPanel from '../../components/LongTermBlockInput'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import BetterTradeLink from '../../components/swap/BetterTradeLink'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from '../../components/swap/styleds'
// import { AddRemoveTabsLongTermSwap } from '../../components/NavigationTabs'
// import TradePrice from '../../components/swap/TradePrice'
import TokenWarningModal from '../../components/TokenWarningModal'
import ProgressSteps from '../../components/ProgressSteps'
// import { Plus } from 'react-feather'
// import { ColumnCenter } from '../../components/Column'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import SwapLongTermHeader from '../../components/swap/SwapLongTermHeader'

// import { BETTER_TRADE_LINK_THRESHOLD, INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { INITIAL_ALLOWED_SLIPPAGE, TWAMM_ADDRESS, useTWAMM_ADDRESS } from '../../constants'
import { getTradeVersion, isTradeBetter } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade, useApproveCallback } from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
// import { useSwapCallback } from '../../hooks/useSwapCallback'
import { useSwapLongTermCallback } from '../../hooks/useSwapLongTermCallback'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field, blockInput } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance } from '../../state/user/hooks'
import { LinkStyledButton, TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBodyMax'
import { ClickableText } from '../Pools/styleds'
import Loader from '../../components/Loader'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { BigNumber } from 'ethers'
import QuestionHelper from '../../components/QuestionHelper'

function getMethods(obj: any) {
  const result = []
  for (const id in obj) {
    try {
      if (typeof obj[id] == 'function') {
        result.push(id + ': ' + obj[id].toString())
      }
    } catch (err) {
      result.push(id + ': inaccessible')
    }
  }
  return result
}

export default function SwapLongTerm() {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const dispatch = useDispatch<AppDispatch>()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId)
  ]
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c instanceof Token) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, []) // [history])

  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const toggleSettings = useToggleSettingsMenu()
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  // const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient, blockValue } = useSwapState()
  const [CurrencyWETH, setCurrencyWETH] = useState<any>()

  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    resTrade
  } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )

  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)
  const toggledVersion = useToggledVersion()

  const trade = showWrap ? undefined : resTrade

  const betterTradeLinkVersion: Version | undefined =
    // toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
    toggledVersion === Version.v2 && isTradeBetter(v2Trade, v1Trade)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )

  const noRoute = !route

  // check whether the user has approved the router on the input token
  // const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)
  const [approval, approveCallback] = useApproveCallback(trade?.inputAmount, useTWAMM_ADDRESS())
  // const [approvalWETH, approveWETHCallback] = useApproveCallback(CurrencyWETH, useTWAMM_ADDRESS(), true)
  const [approvalWETH, approveWETHCallback] = useApproveCallback(CurrencyWETH, useTWAMM_ADDRESS())

  useEffect(() => {
    if (!chainId) return
    if (currencies?.INPUT === ETHER && currencies?.OUTPUT && trade?.inputAmount) {
      if (
        CurrencyWETH &&
        // @ts-ignore
        BigNumber.from(CurrencyWETH?.raw.toString()).eq(BigNumber.from(parsedAmounts[Field.INPUT].raw.toString()))
      )
        return

      let amount = new TokenAmount(WETH[chainId] as any, parsedAmounts[Field.INPUT]?.raw)
      setCurrencyWETH(amount)
    } else {
      setCurrencyWETH(undefined)
    }
  }, [chainId, parsedAmounts, currencies, trade, CurrencyWETH])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // obtian longterm Window number
  const [longTermWindow, setLongTermWindow] = useState(1)

  const handleLongTermWindowChange = (event: any) => {
    setLongTermWindow(event.target.value)
  }

  const { callback: swapLongTermCallback, error: swapCallbackError } = useSwapLongTermCallback(
    trade,
    allowedSlippage,
    recipient,
    currencies,
    blockValue,
    longTermWindow
  )

  // const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const priceImpactWithoutFee = new Percent(JSBI.BigInt(0))

  const handleSwapLongTerm = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapLongTermCallback) {
      return
    }

    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapLongTermCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        // ReactGA.event({
        //   category: 'SwapLongTerm',
        //   action:
        //     recipient === null
        //       ? 'Swap Long Term w/o Send'
        //       : (recipientAddress ?? recipient) === account
        //       ? 'Swap Long Term w/o Send + recipient'
        //       : 'Swap Long Term w/ Send',
        //   label: [
        //     trade?.inputAmount?.currency?.symbol,
        //     trade?.outputAmount?.currency?.symbol,
        //     getTradeVersion(trade)
        //   ].join('/')
        // })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapLongTermCallback])

  // errors
  // const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      approvalWETH === ApprovalState.NOT_APPROVED ||
      approvalWETH === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleBlockValChanges = useCallback(
    (blockValue: string) => {
      dispatch(blockInput({ blockValue }))
      // if (!(Number.isInteger(Number(blockValue)) && Number(blockValue) > 0)) return
    },
    [dispatch]
  )

  const handleBlockValBlur = useCallback(() => {
    if (Number.isInteger(Number(blockValue)) && Number(blockValue) > 0) return
    dispatch(blockInput({ blockValue: '1' }))
  }, [blockValue, dispatch])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
    },
    [onCurrencySelection]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  )

  const checkMantleBase = chainId === 5000 || chainId === 5001

  return (
    <>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
        onDismiss={handleDismissTokenWarning}
      />
      <AppBody>
        <SwapLongTermHeader isMax={true} title={'Term Swap'} settingFlag={true} />
        {/* <AddRemoveTabsLongTermSwap adding={true} /> */}
        <SwapPoolTabs active={'termswap'} />
        <Wrapper id="swaplongterm-page">
          {/* <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwapLongTerm}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          /> */}
          <AutoColumn gap={'tn'}>
            <CurrencyInputPanel
              label={independentField === Field.OUTPUT && !showWrap && trade ? 'Sell (estimated)' : 'Sell'}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swaplongterm-currency-input"
            />
            <div
              style={{
                // maxWidth: '420px',
                width: '100%',
                height: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <ArrowWrapper clickable>
                <ArrowDown
                  size="16"
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    onSwitchTokens()
                  }}
                  color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.primary1 : theme.lightBlue}
                />
              </ArrowWrapper>
              {recipient === null && !showWrap && isExpertMode ? (
                <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                  + Add a send (optional)
                </LinkStyledButton>
              ) : null}
            </div>
            <CurrencyInputPanel
              // value={formattedAmounts[Field.OUTPUT]} //
              // onUserInput={handleTypeOutput}
              value={''}
              onUserInput={() => {}}
              label={independentField === Field.INPUT && !showWrap && trade ? 'Buy (estimated)' : 'Buy'}
              placeholder={'TBD'}
              disableIpt={true}
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swaplongterm-currency-outputb"
            />

            <AutoRow>
              {/* <Plus size="16" color={theme.text2} /> */}
              {/* <Input value='test'/> */}
              {/* <form>
                <label>
                  LongTermWindow:
                  <input type="text" value={longTermWindow} onChange={handleLongTermWindowChange} />
                </label>
                </form> */}
              {/* <QuestionHelper text={'explanations for withdraw'} /> */}
              <LongTermBlockInputPanel
                title="Expires in (unit: 5 blocks)"
                currency={currencies[Field.INPUT]}
                buyCurrency={currencies[Field.OUTPUT]}
                onChange={handleBlockValChanges}
                onBulr={handleBlockValBlur}
                priceDisable={true}
              />
            </AutoRow>

            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                    - Remove send
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
              </>
            ) : null}

            {showWrap ? null : (
              <Card padding={'.25rem .75rem 0 .75rem'} borderRadius={'20px'}>
                <AutoColumn gap="4px">
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={14} color={theme.text2}>
                        {"There won't be an instant price until the withdraw or cancellation of long term order"}
                      </Text>
                      {/* <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      /> */}
                    </RowBetween>
                  )}
                  {/* {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        Slippage Tolerance
                      </ClickableText>
                      <ClickableText fontWeight={500} fontSize={14} color={theme.text2} onClick={toggleSettings}>
                        {allowedSlippage / 100}%
                      </ClickableText>
                    </RowBetween>
                  )} */}
                </AutoColumn>
              </Card>
            )}
          </AutoColumn>

          <BottomGrouping>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : showWrap ? (
              <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                {wrapInputError ??
                  (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: 'center' }}>
                <TYPE.main mb="4px">{swapInputError || 'Insufficient liquidity for this trade.'}</TYPE.main>
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                {/* <ButtonConfirmed
                  onClick={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                  width="48%"
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    'Approved'
                  ) : (
                    'Approve ' + currencies[Field.INPUT]?.symbol
                  )}
                </ButtonConfirmed> */}
                <ButtonConfirmed
                  onClick={CurrencyWETH ? approveWETHCallback : approveCallback}
                  disabled={
                    (approval !== ApprovalState.NOT_APPROVED || approvalSubmitted) &&
                    (approvalWETH !== ApprovalState.NOT_APPROVED || approvalSubmitted)
                  }
                  width="48%"
                  altDisabledStyle={approval === ApprovalState.PENDING && approvalWETH === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED && approvalWETH === ApprovalState.APPROVED}
                >
                  {approval === ApprovalState.PENDING || approvalWETH === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted &&
                    approval === ApprovalState.APPROVED &&
                    approvalWETH === ApprovalState.APPROVED ? (
                    'Approved'
                  ) : CurrencyWETH ? (
                    checkMantleBase ? (
                      'Approve WMNT'
                    ) : (
                      'Approve WETH'
                    )
                  ) : (
                    'Approve ' + currencies[Field.INPUT]?.symbol
                  )}
                </ButtonConfirmed>
                <ButtonError
                  onClick={() => {
                    handleSwapLongTerm()
                    // if (isExpertMode) {
                    //   handleSwapLongTerm()
                    // } else {
                    //   setSwapState({
                    //     tradeToConfirm: trade,
                    //     attemptingTxn: false,
                    //     swapErrorMessage: undefined,
                    //     showConfirm: true,
                    //     txHash: undefined
                    //   })
                    // }
                  }}
                  width="48%"
                  id="swaplongterm-button"
                  disabled={
                    !isValid ||
                    !blockValue ||
                    approval !== ApprovalState.APPROVED ||
                    approvalWETH !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode)
                  }
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactSeverity > 3 && !isExpertMode ? `Price Impact High` : `Submit an Order`}
                  </Text>
                </ButtonError>
              </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  handleSwapLongTerm()
                  // if (isExpertMode) {
                  //   handleSwapLongTerm()
                  // } else {
                  //   setSwapState({
                  //     tradeToConfirm: trade,
                  //     attemptingTxn: false,
                  //     swapErrorMessage: undefined,
                  //     showConfirm: true,
                  //     txHash: undefined
                  //   })
                  // }
                }}
                id="swaplongterm-button"
                disabled={!isValid || !blockValue || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? `Price Impact Too High`
                    : 'Submit an Order'
                  // `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`
                  }
                </Text>
              </ButtonError>
            )}
            <p></p>
            {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
            {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
            {/* {betterTradeLinkVersion && <BetterTradeLink version={betterTradeLinkVersion} />} */}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
      {/* <AdvancedSwapDetailsDropdown trade={trade} /> */}
    </>
  )
}
