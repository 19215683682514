import { Interface } from '@ethersproject/abi'
// import { ChainId } from '@uniswap/sdk'
import { ChainId } from 'constants/chains'
import V1_EXCHANGE_ABI from './v1_exchange.json'
import V1_FACTORY_ABI from './v1_factory.json'

// mainnet: {
//   Factory: "0x408f66057163d829a30D4d466092c6B0eebb692f",
//   TWAMM: "0xcd43AbA971bEF65555D877657F83093dDFB885b8",
// },
// goerli: {
//   Factory: "0x7cA0aFB49064822072E8BA2b0eaC6890495Bc309",
//   TWAMM: "0xc0767d86dDf8b172f2B57AD6Ddf59B35c3170E81",
// },
// Mantle: {
//   Factory: "0xB5B03706C24c79D3F7a368b30562a1711d74F688",
//   TWAMM: "0x8E76C60461eBd76fE54960F50f436ccF24F0D9a6",
// },
// goerliMantle: {
//   Factory: "0x3A9D418E8D54dF96FAADE229dd18128aCd0D77F6",
//   TWAMM: "0xd84c54a3Cf231eA5bBBaaAb1C549b87b396e4aC3",
// },
// arbitrumOne: {
//   Factory: "0x336a2f76d2BE24E7cB6F468665a4277D4d617D00",
//   TWAMM: "0x04538B69f9a637500ECf2b705e05AB38Bab27B7E",
// },
// goerliArbitrum: {
//   Factory: "0x8B6412217B66d299Ae12885F9Aae0d4D3049f53B",
//   TWAMM: "0xdF9E82787Baf7D5A4DE8059d98F0eBeb18c8cf92",
// },

const V1_FACTORY_ADDRESSES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x408f66057163d829a30D4d466092c6B0eebb692f',
  [ChainId.ROPSTEN]: '0x0000',
  [ChainId.RINKEBY]: '0x0000',
  [ChainId.GÖRLI]: '0x7cA0aFB49064822072E8BA2b0eaC6890495Bc309',
  [ChainId.KOVAN]: '0x0000',
  [ChainId.MANTLE]: '0xB5B03706C24c79D3F7a368b30562a1711d74F688',
  [ChainId.MANTLE_GOERLI]: '0x3A9D418E8D54dF96FAADE229dd18128aCd0D77F6',
  [ChainId.ARBITRUM_ONE]: '0x336a2f76d2BE24E7cB6F468665a4277D4d617D00',
  [ChainId.ARBITRUM_GOERLI]: '0x8B6412217B66d299Ae12885F9Aae0d4D3049f53B',
  [ChainId.ARBITRUM_RINKEBY]: '0x0000'
}

const V1_FACTORY_INTERFACE = new Interface(V1_FACTORY_ABI)
const V1_EXCHANGE_INTERFACE = new Interface(V1_EXCHANGE_ABI)

export { V1_FACTORY_ADDRESSES, V1_FACTORY_INTERFACE, V1_FACTORY_ABI, V1_EXCHANGE_INTERFACE, V1_EXCHANGE_ABI }
