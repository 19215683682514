import { BigNumber } from '@ethersproject/bignumber'
import { TransactionResponse } from '@ethersproject/providers'
import { Currency, CurrencyAmount, currencyEquals, ETHER, TokenAmount, JSBI } from '@uniswap/sdk'
import { WETH } from 'constants/token'
import React, { useCallback, useContext, useState, useEffect } from 'react'
import { AlertTriangle, ExternalLink, Plus } from 'react-feather'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { ButtonError, ButtonLight, ButtonPrimary } from '../../components/Button'
import { BlueCard, LightCard } from '../../components/Card'
import { AutoColumn, ColumnCenter } from '../../components/Column'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import { MinimalPositionCard } from '../../components/PositionCard'
import Row, { AutoRow, RowBetween, RowFlat } from '../../components/Row'
import { useTokenBalance } from 'state/wallet/hooks'

import { TWAMM_ADDRESS, useTWAMM_ADDRESS } from '../../constants'
import { useSingleCallResult } from '../../state/multicall/hooks'
import { useMemo } from 'react'
import { PairState } from '../../data/Reserves'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalToggle, useBlockNumber } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { useTransactionAdder } from '../../state/transactions/hooks'
import { useIsExpertMode, useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract, getTWAMMContract } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { isZero } from '../../data/Reserves'
import AppBody from '../AppBody'
import { Dots, Wrapper } from '../Pools/styleds'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { currencyId, useCurrencyId } from 'utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/swap/UnsupportedCurrencyFooter'
import { parseUnits } from 'ethers/lib/utils'
import { tryParseAmount } from 'state/swap/hooks'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React()
  const theme = useContext(ThemeContext)

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const currencyWETH = useCurrency(chainId && WETH[chainId]?.address)

  const currencyId = useCurrencyId()

  const oneCurrencyIsWETH = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId] as any)) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId] as any)))
  )

  const toggleWalletModal = useWalletModalToggle() // toggle wallet when disconnected

  const expertMode = useIsExpertMode()

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    pairRawAddress,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)
  const blockNumber = useBlockNumber()
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  const [pairAddr, setPairAddr] = useState<string | undefined>()

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')
  const [CurrencyWETH, setCurrencyWETH] = useState<any>()
  const [has_eth_pair, setHas_eth_pair] = useState(false)

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field])
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0')
      }
    },
    {}
  )

  // check whether the user has approved the router on the tokens

  const [approvalA, approveACallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_A], useTWAMM_ADDRESS())
  const [approvalB, approveBCallback] = useApproveCallback(parsedAmounts[Field.CURRENCY_B], useTWAMM_ADDRESS())
  // const [approvalWETH, approveWETHCallback] = useApproveCallback(CurrencyWETH, useTWAMM_ADDRESS(), true)
  const [approvalWETH, approveWETHCallback] = useApproveCallback(CurrencyWETH, useTWAMM_ADDRESS())

  const addTransaction = useTransactionAdder()

  useEffect(() => {
    if (oneCurrencyIsWETH) {
      currencyWETH && setCurrencyWETH(undefined)
    }
    if (has_eth_pair && oneCurrencyIsWETH) return
    // getAddr()
    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    const tokenBIsETH = currencyB === ETHER
    setHas_eth_pair(currencyA === ETHER || currencyB === ETHER)

    if (has_eth_pair) {
      if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline || !currencyWETH || !chainId) {
        return
      }
      if (
        CurrencyWETH &&
        // @ts-ignore
        BigNumber.from(CurrencyWETH?.raw.toString()).eq(
          BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
        )
      )
        return
      const { decimals, symbol, name } = currencyWETH
      let amount = new TokenAmount(
        WETH[chainId] as any,
        JSBI.BigInt(BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString()))
      )
      // // @ts-ignore
      // new CurrencyAmount(
      //   // @ts-ignore
      //   new Currency(decimals, symbol, name),
      //   BigNumber.from((tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString())
      // )
      // amount.token =  WETH[chainId]
      setCurrencyWETH(amount)
    } else {
      setCurrencyWETH(undefined)
    }
  }, [
    currencyA,
    currencyB,
    error,
    parsedAmounts,
    CurrencyWETH,
    chainId,
    currencyWETH,
    deadline,
    has_eth_pair,
    oneCurrencyIsWETH
  ])

  async function getAddr() {
    if (!chainId || !library || !account || !currencyA || !currencyB || !currencyWETH) return
    const tokenBIsETH = currencyB === ETHER
    let has_eth_pair = currencyA === ETHER || currencyB === ETHER

    if (has_eth_pair && oneCurrencyIsWETH) return

    const router = getRouterContract(chainId, library, account)

    let pairAddr

    let addrA = wrappedCurrency(!(has_eth_pair && !tokenBIsETH) ? currencyA : currencyWETH, chainId)?.address ?? ''
    let addrB = wrappedCurrency(!(has_eth_pair && tokenBIsETH) ? currencyB : currencyWETH, chainId)?.address ?? ''

    try {
      await router.createPairWrapper(addrA, addrB, deadline)
    } catch (error) {}

    pairAddr = await router.obtainPairAddress(addrA, addrB)

    setPairAddr(pairAddr)
  }

  async function onAdd() {
    if (!chainId || !library || !account) return
    const router = getRouterContract(chainId, library, account)
    const liquidityCont = getTWAMMContract(chainId, library, account)

    if (!liquidityCont) return

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB || !deadline) {
      return
    }
    // console.log(
    //   'parsedAmountA',
    //   // parsedAmountA
    //   //   .multiply(JSBI.add(JSBI.BigInt(10000), JSBI.BigInt(allowedSlippage)))
    //   //   .divide(JSBI.BigInt(10000))
    //   //   .toSignificant(6),
    //   getAmountInMax(parsedAmountA, allowedSlippage).toSignificant(6),
    //   tryParseAmount(getAmountInMax(parsedAmountA, allowedSlippage)?.toSignificant(6), currencyA)?.raw.toString() ||
    //     '0',
    //   getAmountInMax(parsedAmountB, allowedSlippage).toSignificant(6),
    //   tryParseAmount(getAmountInMax(parsedAmountB, allowedSlippage)?.toSignificant(6), currencyB)?.raw.toString() ||
    //     '0',
    //   currencyA,
    //   parsedAmountB,
    //   parsedAmountB.toSignificant(6),
    //   allowedSlippage
    // )
    // return

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
    }

    let estimate,
      pairAddr,
      pairAmount,
      method: (...args: any) => Promise<TransactionResponse>,
      args: Array<string | string[] | number | BigNumber>,
      value: BigNumber | null

    let has_eth_pair = currencyA === ETHER || currencyB === ETHER

    let addrA = wrappedCurrency(currencyA, chainId)?.address ?? ''
    let addrB = wrappedCurrency(currencyB, chainId)?.address ?? ''

    try {
      await router.createPairWrapper(addrA, addrB, deadline)
    } catch (error) {}
    pairAddr = await router.obtainPairAddress(addrA, addrB)

    setPairAddr(pairAddr)

    try {
      pairAmount = await router.obtainTotalSupply(addrA, addrB)
    } catch (error) {
      pairAmount = '0x00'
    }

    if (
      approvalA === ApprovalState.NOT_APPROVED ||
      approvalA === ApprovalState.PENDING ||
      approvalB === ApprovalState.NOT_APPROVED ||
      approvalB === ApprovalState.PENDING
    )
      return

    const tokenBIsETH = currencyB === ETHER

    const CURRENCY_A_SYMBOL = checkMantleBase
      ? currencies[Field.CURRENCY_A]?.symbol === 'ETH'
        ? 'MNT'
        : currencies[Field.CURRENCY_A]?.symbol
      : currencies[Field.CURRENCY_A]?.symbol

    const CURRENCY_B_SYMBOL = checkMantleBase
      ? currencies[Field.CURRENCY_B]?.symbol === 'ETH'
        ? 'MNT'
        : currencies[Field.CURRENCY_B]?.symbol
      : currencies[Field.CURRENCY_B]?.symbol

    if (isZero(pairAmount)) {
      if (has_eth_pair) {
        estimate = router.estimateGas.addInitialLiquidityETH
        method = router.addInitialLiquidityETH
        args = [
          wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '', // token
          (tokenBIsETH ? parsedAmountA : parsedAmountB).raw.toString(), //, // token desired
          (tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString(),
          deadline.toString()
        ]
        value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
      } else {
        estimate = router.estimateGas.addInitialLiquidity
        method = router.addInitialLiquidity
        args = [addrA, addrB, parsedAmountA.raw.toString(), parsedAmountB.raw.toString(), deadline.toString()]
        value = null
      }
    } else {
      let res = await router.obtainReserves(addrA, addrB)
      // const { reserve0, reserve1 } = res
      // const newLPTokens = BigNumber.from(parsedAmountA.raw.toString())
      //   .mul(reserve0)
      //   .div(pairAmount)

      // const userBalanceA = useTokenBalance(account ?? undefined, addrA)
      // const userBalanceA = parseUnits('100000000000000000000').toString()
      // const userBalanceB = parseUnits('100000000000000000000').toString() //BigNumber.from(10 ** 30).toString()
      // const userBalanceB = useTokenBalance(account ?? undefined, addrB)
      const defaultMax = parseUnits('100000000000000000000').toString()
      const userBalanceA =
        tryParseAmount(getAmountInMax(parsedAmountA, allowedSlippage)?.toSignificant(6), currencyA)?.raw.toString() ||
        defaultMax
      const userBalanceB =
        tryParseAmount(getAmountInMax(parsedAmountB, allowedSlippage)?.toSignificant(6), currencyB)?.raw.toString() ||
        defaultMax //BigNumber.from(10 ** 30).toString()

      const newLPTokens = BigNumber.from(liquidityMinted?.raw.toString())
      estimate = liquidityCont?.estimateGas.addLiquidity
      method = liquidityCont?.addLiquidity
      args = [addrA, addrB, newLPTokens.toString(), userBalanceA, userBalanceB, deadline.toString()]
      value = null
      if (has_eth_pair) {
        estimate = liquidityCont?.estimateGas.addLiquidityETH
        method = liquidityCont?.addLiquidityETH
        const tokenArr = tokenBIsETH ? [userBalanceA, userBalanceB] : [userBalanceB, userBalanceA]
        //const tokenArr = [defaultMax, defaultMax]

        args = [
          wrappedCurrency(tokenBIsETH ? currencyA : currencyB, chainId)?.address ?? '',
          newLPTokens.toString(),
          ...tokenArr,
          deadline.toString()
        ]
        value = BigNumber.from((tokenBIsETH ? parsedAmountB : parsedAmountA).raw.toString())
      }
    }

    setAttemptingTxn(true)
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit)
        }).then(response => {
          setAttemptingTxn(false)

          addTransaction(response, {
            summary:
              'Add ' +
              parsedAmounts[Field.CURRENCY_A]?.toSignificant(4) +
              ' ' +
              CURRENCY_A_SYMBOL +
              ' and ' +
              parsedAmounts[Field.CURRENCY_B]?.toSignificant(4) +
              ' ' +
              CURRENCY_B_SYMBOL
          })

          setTxHash(response.hash)

          // ReactGA.event({
          //   category: 'Liquidity',
          //   action: 'Add',
          //   label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/')
          // })
        })
      )
      .catch(error => {
        setAttemptingTxn(false)
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error)
        }
      })
  }
  //const { chainId } = useActiveWeb3React()
  //const checkMantleBase = chainId === 5000 || chainId === 5001

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <LightCard mt="20px" borderRadius="20px">
          <RowFlat>
            <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
          </RowFlat>
        </LightCard>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <RowFlat style={{ marginTop: '20px' }}>
          <Text fontSize="48px" fontWeight={500} lineHeight="42px" marginRight={10}>
            {liquidityMinted?.toSignificant(6)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={30}
          />
        </RowFlat>
        <Row>
          <Text fontSize="24px">
            {checkMantleBase
              ? currencies[Field.CURRENCY_A]?.symbol === 'ETH'
                ? 'MNT'
                : currencies[Field.CURRENCY_A]?.symbol
              : currencies[Field.CURRENCY_A]?.symbol}
            {'/'}
            {checkMantleBase
              ? currencies[Field.CURRENCY_B]?.symbol === 'ETH'
                ? 'MNT'
                : currencies[Field.CURRENCY_B]?.symbol
              : currencies[Field.CURRENCY_B]?.symbol}
            {' Pool Tokens'}
          </Text>
        </Row>
        <TYPE.italic fontSize={12} textAlign="left" padding={'8px 0 0 0 '}>
          {`Output is estimated. If the price changes by more than ${allowedSlippage /
            100}% your transaction will revert.`}
        </TYPE.italic>
      </AutoColumn>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = `Supplying ${parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)} ${
    currencies[Field.CURRENCY_A]?.symbol
  } and ${parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} ${currencies[Field.CURRENCY_B]?.symbol}`

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyId, currencyIdB, history, currencyIdA]
  )
  //const { chainId } = useActiveWeb3React()
  const checkMantleBase = chainId === 5000 || chainId === 5001

  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : checkMantleBase ? 'MNT' : 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyId, currencyIdA, currencyIdB, history, checkMantleBase]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  // const addIsUnsupported = useIsTransactionUnsupported(currencies?.CURRENCY_A, currencies?.CURRENCY_B)
  const addIsUnsupported = false

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? 'You are creating a pool' : 'You will receive'}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
            currencyToAdd={pair?.liquidityToken}
          />
          <AutoColumn gap="20px">
            {noLiquidity ||
              (isCreate ? (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      <TYPE.link fontWeight={600} color={'primaryText1'}>
                        You are the first liquidity provider.
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        The ratio of tokens you add will set the price of this pool.
                      </TYPE.link>
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        Once you are happy with the rate click supply to review.
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ) : (
                <ColumnCenter>
                  <BlueCard>
                    <AutoColumn gap="10px">
                      {/* <AutoColumn justify="center" style={{ textAlign: 'center', gap: '16px', marginBottom: '12px' }}>
                        <AlertTriangle stroke={theme.red1} size={32} />
                        <TYPE.link fontWeight={600} fontSize={20} color={theme.red1}>
                          Trade at your own risk!
                        </TYPE.link>
                      </AutoColumn>
                      <AutoColumn style={{ gap: '16px', marginBottom: '12px' }}>
                        <TYPE.link fontWeight={400} color={theme.red1}>
                          Notice: We current do not support{' '}
                          <a
                          href="https://github.com/d-xo/weird-erc20/#fee-on-transfer"
                          style={{ textDecoration: 'underline' }}
                          color={theme.red1}
                          target="_blank"
                          rel="noreferrer"
                        >
                          fee-on-transfer
                        </a>{' '}
                          tokens, swap such tokens will fail!
                        </TYPE.link>
                        <TYPE.body fontWeight={600} color={theme.red1}>
                          If you purchase this token, you may not be able to sell it back.
                        </TYPE.body>
                      </AutoColumn> */}
                      <TYPE.link fontWeight={400} color={'primaryText1'}>
                        <b>Tip:</b> When you add liquidity, you will receive pool tokens representing your position.
                        These tokens automatically earn fees proportional to your share of the pool, and can be redeemed
                        at any time.
                      </TYPE.link>
                    </AutoColumn>
                  </BlueCard>
                </ColumnCenter>
              ))}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A]}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokena"
              showCommonBases
            />
            <ColumnCenter>
              <Plus size="16" color={theme.text2} />
            </ColumnCenter>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokenb"
              showCommonBases
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                <LightCard padding="0px" borderRadius={'20px'}>
                  <RowBetween padding="1rem">
                    <AutoColumn style={{ gap: '16px' }}>
                      <TYPE.link fontWeight={400} color={theme.red1}>
                        Notice: We current do not support{' '}
                        <a
                          href="https://github.com/d-xo/weird-erc20/#fee-on-transfer"
                          style={{ textDecoration: 'underline' }}
                          color={theme.red1}
                          target="_blank"
                          rel="noreferrer"
                        >
                          fee-on-transfer
                        </a>{' '}
                        tokens, swap such tokens will fail!
                      </TYPE.link>
                    </AutoColumn>
                  </RowBetween>
                  <RowBetween padding="1rem">
                    <TYPE.subHeader fontWeight={500} fontSize={14}>
                      {noLiquidity ? 'Initial prices' : 'Prices'} and pool share
                    </TYPE.subHeader>
                  </RowBetween>{' '}
                  <LightCard padding="1rem" borderRadius={'20px'}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </LightCard>
                </LightCard>
              </>
            )}

            {addIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <TYPE.main mb="4px">Unsupported Asset</TYPE.main>
              </ButtonPrimary>
            ) : !account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect Wallet</ButtonLight>
            ) : (
              <AutoColumn gap={'md'}>
                {(approvalA === ApprovalState.NOT_APPROVED ||
                  approvalA === ApprovalState.PENDING ||
                  approvalB === ApprovalState.NOT_APPROVED ||
                  approvalB === ApprovalState.PENDING ||
                  approvalWETH === ApprovalState.NOT_APPROVED ||
                  approvalWETH === ApprovalState.PENDING) &&
                  isValid && (
                    <RowBetween>
                      {approvalA !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveACallback}
                          disabled={approvalA === ApprovalState.PENDING}
                          width={
                            approvalB !== ApprovalState.APPROVED ||
                            (approvalWETH !== ApprovalState.APPROVED && has_eth_pair)
                              ? '48%'
                              : '100%'
                          }
                        >
                          {approvalA === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_A]?.symbol}</Dots>
                          ) : (
                            'Approve  ' + currencies[Field.CURRENCY_A]?.symbol
                          )}
                        </ButtonPrimary>
                      )}
                      {approvalB !== ApprovalState.APPROVED && (
                        <ButtonPrimary
                          onClick={approveBCallback}
                          disabled={approvalB === ApprovalState.PENDING}
                          width={
                            approvalA !== ApprovalState.APPROVED ||
                            (approvalWETH !== ApprovalState.APPROVED && has_eth_pair)
                              ? '48%'
                              : '100%'
                          }
                        >
                          {approvalB === ApprovalState.PENDING ? (
                            <Dots>Approving {currencies[Field.CURRENCY_B]?.symbol}</Dots>
                          ) : (
                            'Approve ' + currencies[Field.CURRENCY_B]?.symbol
                          )}
                        </ButtonPrimary>
                      )}
                      {approvalWETH !== ApprovalState.APPROVED && has_eth_pair && (
                        <ButtonPrimary
                          onClick={approveWETHCallback}
                          disabled={approvalWETH === ApprovalState.PENDING}
                          width={
                            approvalA !== ApprovalState.APPROVED || approvalB !== ApprovalState.APPROVED
                              ? '48%'
                              : '100%'
                          }
                        >
                          {checkMantleBase ? (
                            approvalWETH === ApprovalState.PENDING ? (
                              <Dots>Approving WMNT</Dots>
                            ) : (
                              'Approve  WMNT'
                            )
                          ) : approvalWETH === ApprovalState.PENDING ? (
                            <Dots>Approving WETH</Dots>
                          ) : (
                            'Approve  WETH'
                          )}
                        </ButtonPrimary>
                      )}
                    </RowBetween>
                  )}
                <ButtonError
                  onClick={() => {
                    // expertMode ? onAdd() : setShowConfirm(true)
                    expertMode ? onAdd() : setShowConfirm(true)
                  }}
                  disabled={
                    !isValid ||
                    approvalA !== ApprovalState.APPROVED ||
                    approvalB !== ApprovalState.APPROVED ||
                    (CurrencyWETH && approvalWETH !== ApprovalState.APPROVED && has_eth_pair)
                  }
                  error={!isValid && !!parsedAmounts[Field.CURRENCY_A] && !!parsedAmounts[Field.CURRENCY_B]}
                >
                  <Text fontSize={20} fontWeight={500}>
                    {error ?? 'Supply'}
                  </Text>
                </ButtonError>
              </AutoColumn>
            )}
          </AutoColumn>
        </Wrapper>
      </AppBody>
      {!addIsUnsupported ? (
        pair && !noLiquidity && pairState !== PairState.INVALID ? (
          <AutoColumn style={{ minWidth: '20rem', width: '100%', maxWidth: '400px', marginTop: '1rem' }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null
      ) : (
        <UnsupportedCurrencyFooter
          show={addIsUnsupported}
          currencies={[currencies.CURRENCY_A, currencies.CURRENCY_B]}
        />
      )}
    </>
  )
}

export const getAmountInMax = (currencyAmount: CurrencyAmount, allowedSlippage: number) =>
  currencyAmount?.multiply(JSBI.add(JSBI.BigInt(10000), JSBI.BigInt(allowedSlippage))).divide(JSBI.BigInt(10000))
