// import { ChainId } from '@uniswap/sdk'
import { ChainId } from 'constants/chains'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.MANTLE]: '0x579aE5ceEB127c98cD48d0bbdA45Ea66C271046c',
  [ChainId.MANTLE_GOERLI]: '0x4CA97c6915f87283CD8DA4432352910c34ddF47C',
  [ChainId.ARBITRUM_ONE]: '0xB064Fe785d8131653eE12f3581F9A55F6D6E1ca3',
  [ChainId.ARBITRUM_GOERLI]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.ARBITRUM_RINKEBY]: '0xcA11bde05977b3631167028862bE2a173976CA11'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
