import React, { useRef, useState, useEffect, useCallback } from 'react'
import { darken, lighten } from 'polished'
import { BookOpen, Code, Info, MessageCircle, PieChart, Droplet, ChevronDown, ChevronUp } from 'react-feather'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
// import { useActiveWeb3React } from '../../hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal, useWalletModalToggle } from '../../state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { ExternalLink } from '../../theme'
import { JSBI } from '@uniswap/sdk'
// import { ButtonPrimary } from '../Button'
import { BigNumber } from '@ethersproject/bignumber'
import { SupportedChainId, CHAIN_IDS_TO_NAMES } from 'constants/chains'
import { getChainInfo } from 'constants/chainInfo'
import { YellowCard } from '../Card'
import { getRpcUrl } from 'utils/switchChain'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text1};
  }
`

const NoWarp = styled.span`
  white-space: nowrap;
`

const StyledMenuButton = styled(YellowCard)`
  min-width: 100%;
  height: 100%;
  border: none;
  /* background-color: transparent; */
  margin: 0;
  /* padding: 0; */
  display: flex;
  align-items: center;
  padding: 8px 12px;
  /* height: 35px; */
  /* background-color: ${({ theme }) => darken(0.05, theme.yellow2)}; */

  /* padding: 0.15rem 0.5rem; */
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    /* background-color: ${({ theme }) => lighten(0.05, theme.yellow2)}; */
    background-color: rgba(243,132,30,0.3);
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 8.125rem;
  background-color: ${({ theme }) => theme.bg3};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: -17.25rem;
  `};
`

const MenuItem = styled.div`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  display: flex;
  align-items: center;
  border-radius: 12px;
  :hover {
    color: ${({ theme }) => theme.text1};
    cursor: pointer;
    text-decoration: none;
    background-color: gray;
  }
  > svg {
    margin-right: 8px;
  }
`

const MarginSpan = styled.span`
  margin-left: 6px;
  display: inline-flex;
`

const LogoImg = styled.img`
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  margin-right: 8px;
`

const CODE_LINK = 'https://github.com/PulsarSwap/TWAMM-Interface'

export default function Menu({ children }: any) {
  // const { account } = useActiveWeb3React()

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.NETWORK)
  const toggle = useToggleModal(ApplicationModal.NETWORK)
  useOnClickOutside(node, open ? toggle : undefined)
  const [network, setNetwork] = useState<any>(undefined)
  const { library, chainId, account, activate, deactivate, active, error } = useWeb3React()
  const supportChainArr = [
    SupportedChainId.MAINNET,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.MANTLE
    //SupportedChainId.OPTIMISM,
    //SupportedChainId.GOERLI,
    //SupportedChainId.ARBITRUM_GOERLI,
    //SupportedChainId.MANTLE_GOERLI
  ]
  const toggleWalletModal = useWalletModalToggle()
  const switchNetwork = async (network: any) => {
    try {
      if (!library) return toggleWalletModal()
      await library?.provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(network) }]
      })
      setNetwork(network)
      // toggle()
    } catch (switchError) {
      // if (switchError.code === 4902) {
      //   try {
      //     const { label } = getChainInfo(network)
      //     console.log('getChainInfo(network)', {
      //       chainId: toHex(network),
      //       chainName: label,
      //       rpcUrls: [getRpcUrl(network)]
      //     })
      //     // const addChainParameter = {
      //     //   chainId,
      //     //   chainName: info.label,
      //     //   rpcUrls: [getRpcUrl(network)],
      //     //   nativeCurrency: info.nativeCurrency,
      //     //   blockExplorerUrls: [info.explorer]
      //     // }
      //     await library.provider.request({
      //       method: 'wallet_addEthereumChain',
      //       params: [{ chainId: toHex(network), chainName: label, rpcUrls: [getRpcUrl(network)] }]
      //     })
      //   } catch (error) {
      //     // setError(error)
      //   }
      // }
    }
  }

  useEffect(() => {
    if (!network) return
    // switchNetwork()
    toggle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network])

  const toHex = (num: any) => {
    const val = Number(num)
    return '0x' + val.toString(16)
  }

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <NoWarp>{children}</NoWarp>
        {/* <StyledMenuIcon /> */}
        <MarginSpan>{!open ? <ChevronDown color="#F3841E" /> : <ChevronUp color="#F3841E" />}</MarginSpan>
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          {supportChainArr.map(e => (
            <MenuItem id="link" key={e} onClick={() => switchNetwork(e)}>
              {/* <Info size={14} /> */}
              <LogoImg src={getChainInfo(e).logoUrl}></LogoImg>
              <NoWarp>{getChainInfo(e).label}</NoWarp>
            </MenuItem>
          ))}
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
